<template>
	<component :is="component" :style="containerHeight > 0 ? `height: ${containerHeight}px` : undefined" v-bind="$attrs">
		<RecycleScroller
			v-if="containerHeight > 0"
			ref="scroller"
			emit-update
			:items="items"
			:item-size="itemSize"
			:buffer="buffer"
			:key-field="keyField"
			style="height: 100%"
			@update="onUpdate"
		>
			<template v-slot:default="props">
				<slot v-bind="props" />
			</template>
		</RecycleScroller>
		<!-- skeleton for edge cases like modal parent -->
		<template v-else-if="skeleton">
			<slot v-for="(item, index) in items" :item="item" :index="index" />
		</template>
	</component>
</template>

<script>
import { VList } from 'vuetify/lib'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'WVirtualList',
	mixins: [AppModuleGuard],
	props: {
		items: {
			required: false,
			type: Array,
			default: () => []
		},
		component: {
			required: false,
			type: Function,
			// to do support other component like VExpansionPanel
			default: VList
		},
		skeleton: {
			required: false,
			type: Boolean,
			default: false
		},
		keyField: {
			required: false,
			type: String,
			default: 'id'
		},
		buffer: {
			required: false,
			type: Number,
			default: 200
		},
		itemIndexToScrollTo: {
			required: false,
			type: Number,
			default: 0
		}
	},
	data: function () {
		return {
			containerHeight: 0
		}
	},
	computed: {
		itemSize: function () {
			return this.$attrs.dense ? 48 : 56
		}
	},
	mounted: function () {
		this.computeHeight(1000)
		window.addEventListener('resize', this.computeHeight, { passive: true })
		this.scrollToItem()
	},
	beforeDestroy: function () {
		if (typeof window !== 'undefined') {
			window.removeEventListener('resize', this.computeHeight, { passive: true })
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [{ event: this.appEvents.VIRTUAL_SCROLL_RESIZE, action: this.computeHeight }]
		},
		computeHeight: function (timeout = 300) {
			const { $el } = this
			const { parentNode } = $el
			const siblings = Array.from(parentNode.children).filter(child => child !== $el)

			this.containerHeight = 0

			setTimeout(() => {
				const siblingsHeight = siblings.reduce((previousValue, sibling) => previousValue + sibling.offsetHeight, 0)
				const parentHeight = parentNode.offsetHeight
				const newHeight = parentHeight - siblingsHeight - 1
				this.containerHeight = newHeight
				this.scrollToItem()
			}, timeout)
		},
		onUpdate (startIndex, endIndex, visibleStartIndex, visibleEndIndex) {
			this.$emit('update:visible-items', {
				items: this.items.slice(visibleStartIndex, visibleEndIndex + 1),
				visibleStartIndex,
				visibleEndIndex,
			})
		},
		scrollToItem (timeout = 10) {
			if (this.itemIndexToScrollTo >= 0) {
				setTimeout(() => {
				let scroll = (this.itemIndexToScrollTo * this.itemSize)
				const scroller = this.$refs.scroller
				if (scroller) {
					scroller.scrollToPosition(scroll)
				}
				}, timeout)
			}
    	}
	}
}
</script>
